<script setup>
import { RouterLink } from 'vue-router'
import {useCollapsibleStore} from "@/stores/useCollapsibleStore";
import {useModuleStatesStore} from "@/stores/useModuleStatesStore";

const collapsibleState = useCollapsibleStore()
const moduleState = useModuleStatesStore()
</script>

<template>
  <!-- overflow-auto is a temporary solution to avoid whitespace
      when scrolling the content horizontally on smaller devices

      Future feature: an arrow at end of navbar to scroll overflowed links
  -->
  <nav id="header-nav" class="header-nav position-relative d-flex text-center text-uppercase fw-bold overflow-x-auto">
    <div class="mobile-menu border-start" style="background-color: var(--app-main-theme)" v-if="moduleState.isFromAccounting">
      <button class="btn" @click="collapsibleState.collapseComponent('sidebar')">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 512 512"><path fill="none" stroke="white" stroke-linecap="round" stroke-miterlimit="10" stroke-width="48" d="M88 152h336M88 256h336M88 360h336"/></svg>
      </button>
    </div>
    <div class="header-nav-link first-nav-link" :class="!moduleState.isFromAccounting ? 'ms-0' : ''">
        <RouterLink to="/dashboard" v-if="moduleState.isFromAccounting">Dashboard</RouterLink>
    </div>
    <div class="header-nav-link">
        <RouterLink :to="{ name: 'transactions' }" v-if="moduleState.isFromAccounting">Transactions</RouterLink>
    </div>
    <div class="header-nav-link">
      <RouterLink to="/reports" v-if="moduleState.isFromAccounting">Reports</RouterLink>
    </div>
    <div class="header-nav-link">
        <RouterLink to="/management" v-if="moduleState.isFromAccounting">Management</RouterLink>
    </div>
    <div class="header-nav-link">
        <RouterLink to="/monitoring" v-if="moduleState.isFromAccounting">Monitoring</RouterLink>
    </div>
    <div class="header-nav-link">
      <RouterLink to="/entities" v-if="moduleState.isFromAccounting">Entities</RouterLink>
    </div>
    <div class="header-nav-link">
      <RouterLink to="/setup" v-if="moduleState.isFromAccounting">Setup</RouterLink>
    </div>
    <div class="header-nav-link">
      <RouterLink to="/liquidation" v-if="!moduleState.isFromAccounting">Liquidation</RouterLink>
    </div>
    <div class="header-nav-link">
      <RouterLink to="/entities/suppliers" v-if="!moduleState.isFromAccounting">Suppliers</RouterLink>
    </div>
  </nav>
</template>

<style scoped>
a {
    color: white;
    text-decoration: none;
}
a.router-link-active {
    color: white;
}
.btn { padding: 0; }
.mobile-menu {
  padding: 1px 10px !important; border-left: none !important; position: fixed !important; top: 2.9rem;
}

::-webkit-scrollbar {
  height: .5em;
  background-color: white;
}
/* Make scrollbar visible when needed */
::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
}
/* Make scrollbar track visible when needed */
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.header-nav-link:hover {
  background-color: var(--app-secondary-dark);
}

@media (width >= 768px) {
  .mobile-menu { position: absolute !important; top: 0;}
}
</style>
