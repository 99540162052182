import { defineStore } from "pinia";
import {Utility} from "@/utility";

export const useGlobalStore = defineStore("globalState", {
    state: () => ({
      banks: [],
      nodeAccounts: [],
      suppliers: [],
      purchasers: [],
      regions: [],
      provinces: [],
      cities: [],
      barangays: [],
      agents: [],
      taxes: [],
      paymentTerm: [],
      branches: [],
      department: [],
      entities: [],
      glAccounts: [],
      slAccounts: [],
      mappings: [],
      modes: [],
      request_header: '',
    }),

    getters: {
      getBanks: (state) => state.banks,
      getRegions: (state) => state.regions,
      getProvinces: (state) => state.provinces,
      getCities: (state) => state.cities,
      getBarangays: (state) => state.barangays,
      getAgent: (state) => state.agents,
      getTaxes: (state) => state.taxes,
      getNodeAccounts: (state) => state.nodeAccounts,
      getPaymentTerms: (state) => state.paymentTerms,
      getBranches: (state) => state.branches,
      getGLAccounts : (state) => state.glAccounts,
      getSLAccounts : (state) => state.slAccounts,
      getEntities : (state) => state.entities,
      getMappings : (state) => state.mappings,
    }
})
