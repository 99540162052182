import {defineStore} from "pinia";
import {ref} from "vue";

export const useModuleStatesStore = defineStore('module_state', () => {
    // states when loading, etc.
    const isLoading = ref(false)
    const isFilled = ref(true)
    const mode = ref('add')
    const copiedTransactions = ref()
    const entity_type = ref()
    const isFromAccounting = ref(true)
    const selected_taxes = ref([])
    const selected_data = ref([])
    const select_all_cbox = ref(false)
    let time_out
    const showInPageAlert = () => {
        clearInterval(time_out)
        isFilled.value = false
        time_out = setTimeout(() => {
            isFilled.value = true;
            clearInterval(time_out)
        }, 5000)
    }
    return {
        isLoading,
        isFilled,
        mode,
        entity_type,
        selected_taxes,
        selected_data,
        select_all_cbox,
        showInPageAlert,
        copiedTransactions,
        isFromAccounting
    }
})