export const Utility = {
  camelCaseProperties (object) {
    let newObject = {};
    try {
      if (Array.isArray(object)) {
        newObject = object.map(object => this.camelCaseProperties(object));
      } else {
        Object.keys(object).forEach(oldKey => {
          if (Array.isArray(object[oldKey])) newObject[oldKey] = object[oldKey].map(object => this.camelCaseProperties(object))
          else if (typeof object[oldKey] === "object" && !Array.isArray(object[oldKey])) {
            newObject[oldKey] = this.camelCaseProperties(object[oldKey]);
          } else {
            newObject[oldKey] = object[oldKey];
          }

          //from snake_case to camelCase keys
          if (oldKey.includes("_")) {
            let arrayKeys = oldKey.split("_");

            for (let index = 1; index < arrayKeys.length; index++) {
              arrayKeys[index] = arrayKeys[index].charAt(0).toUpperCase().concat(arrayKeys[index].slice(arrayKeys[index].length > 1 ? 1 : 0))
            }

            newObject[arrayKeys.join("")] = object[oldKey];
            delete newObject[oldKey];
          }
        })
      }
    } catch (e) {
      return newObject;
    }

    return newObject;
  },

  initDataTable (method, dataTableId, key = null) {
    const dataTable = window.$('#' + dataTableId).DataTable();
    dataTable.on('click', 'tr', function (event) {
      const selectedData = dataTable.row(this).data();
      if (selectedData === undefined) return;
      if (key !== null){
        method(selectedData[key]);
        return;
      }
      method(selectedData);
    });
  },

  initDataTableWithDelete (method, table, key = null) {
    const dataTable = window.$('#' + table).DataTable();
    dataTable.on('click', 'tr td:not(:last-child)', function (event) {
      const selectedData = dataTable.row(this).data();
      if (selectedData === undefined) return;
      if (key !== null){
        method(selectedData[key]);
        return;
      }
      method(selectedData);
    });
  },

  onClickDataTableDelete(method, tableId, key = null) {
    const datatable = window.$('#' + tableId).DataTable();
    datatable.on('click', 'button.removeRow', function(event) {
      const selectedData = datatable.row(this.closest('tr')).data();
      if (selectedData === undefined) return;
      if (key !== null){
        method(selectedData[key]);
        return;
      }
      method(selectedData);
    });
  },

  copyObjectValuesToRef(object, ref) {
    for (const key of Object.keys(ref)) {
      if (object[key] === undefined) continue;
      ref[key] = object[key];
    }
  }
}
