<script setup>
import Header from '@/components/layout/Header.vue'
import Navbar from '@/components/layout/Navbar.vue'
import Sidebar from '@/components/base/Sidebar.vue'
import ContainerFluid from '@/components/base/ContainerFluid.vue'
import ManagementLinks from "@/components/sidebar-links/ManagementLinks.vue";
import TransactionsLinks from "@/components/sidebar-links/TransactionsLinks.vue";
import MonitoringLinks from "@/components/sidebar-links/MonitoringLinks.vue";
import ReportsLinks from "@/components/sidebar-links/ReportsLinks.vue";
import SetupLinks from "@/components/sidebar-links/SetupLinks.vue";
import EntityLinks from "@/components/sidebar-links/EntityLinks.vue";
import {useCollapsibleStore} from "@/stores/useCollapsibleStore";
// when dynamically getting logo, change url or get from db
import Image from '@/assets/ftlp-logo-1.png'
import DashboardLinks from "@/components/sidebar-links/DashboardLinks.vue";
import {sidebarSublinkCollapse} from "@/stores/subSidebarLinkCollapse";
import {onMounted, ref} from "vue";
import {useModuleStatesStore} from "@/stores/useModuleStatesStore";

const collapsibleState = useCollapsibleStore()
sidebarSublinkCollapse()

onMounted(() => {
  // TODO: set user restriction for non accounting in liquidation and set state for module visibility
 useModuleStatesStore().isFromAccounting = true
})
</script>

<template>
  <div id="dashboard">
    <header id="master-header" class="sticky-top">
        <Header :logo="Image"/>
        <Navbar />
    </header>

    <ContainerFluid>
      <template #containerContent>
        <div class="row g-0">

          <div class="left-main-view z-3" :class="collapsibleState.isSidebarCollapsed || !useModuleStatesStore().isFromAccounting ? 'collapse-container' : ''">
            <Sidebar #sidebarContent :class="[ collapsibleState.isSidebarCollapsed ? 'sidebarOpened' : '' ]" v-if="useModuleStatesStore().isFromAccounting">
              <!--On setting the content of the sidebar based on the active route,
                  check if the container of parent route is or has child that is the active route.
                  The hiding of non-active routes is done through css
              -->
              <DashboardLinks />
              <TransactionsLinks v-if="useModuleStatesStore().isFromAccounting"/>
              <ManagementLinks v-if="useModuleStatesStore().isFromAccounting"/>
              <ReportsLinks v-if="useModuleStatesStore().isFromAccounting"/>
              <MonitoringLinks v-if="useModuleStatesStore().isFromAccounting"/>
              <SetupLinks v-if="useModuleStatesStore().isFromAccounting"/>
              <EntityLinks/>
            </Sidebar>
          </div>
          <div class="right-main-view">
            <RouterView class="px-2 pt-3"/>
          </div>
        </div>
      </template>
    </ContainerFluid>
  </div>
</template>

<style scoped>

</style>