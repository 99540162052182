import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faAngleDown,
    faUser,
    faFileLines,
    faFolderOpen,
    faPlus,
    faCircleCheck,
    faCircleXmark,
    faEyeSlash,
    faEye,
    faFloppyDisk,
    faXmark,
    faRotateLeft,
    faRotate,
    faTrashCan,
    faPrint,
    faSquareMinus,
    faSquarePlus,
    faSquareCaretUp,
    faL,
    faMinus,
    faCircleInfo,
    faPencil,
    faBan,
    faGripVertical,
    faRightFromBracket,
    faWallet,
    faMoneyBillTrendUp,
    faMoneyBillTransfer,
    faSackDollar,
    faCircleDollarToSlot,
    faChevronDown,
    faCaretDown,
    faTriangleExclamation,
    faEllipsisVertical,
    faSquarePen,
    faPenToSquare,
    faAddressBook,
    faCircle,
    faArrowsRotate,
    faFilter,
    faArrowUpLong,
    faArrowDownLong,
    faEllipsis,
    faGear,
    faCheck,
    faCirclePlus,
    faCopy,
    faMagnifyingGlass,
    faDownload,
    faUpload,
    faFileArrowUp,
    faCircleExclamation,
    faClock,
    faFileCirclePlus,
    faPlusMinus,
    faSquareCaretDown,
    faPaperclip,
    faCircleMinus,
    faArrowRightArrowLeft,
    faArrowRotateLeft,
    faBullhorn,
    faThumbtack,
    faCalendarDays,
    faUpRightAndDownLeftFromCenter, faExpand, faCompress, faListCheck, faTableCells, faArrowUpFromBracket
} from '@fortawesome/free-solid-svg-icons'
import { faFolderClosed, faCircleCheck as regularCheck, faCircleXmark as regularCircleXMark} from '@fortawesome/free-regular-svg-icons'

export default () => {
    library.add(
        faAngleDown,
        faUser,
        faFileLines,
        faPlus,
        faFolderOpen,
        faFolderClosed,
        faCircleCheck,
        faCircleXmark,
        faEyeSlash,
        faEye,
        faFloppyDisk,
        faXmark,
        faRotateLeft,
        faRotate,
        faTrashCan,
        faPrint,
        faSquareMinus,
        faSquarePlus,
        faSquareCaretUp,
        faL,
        faMinus,
        faCircleInfo,
        faPencil,
        faBan,
        faGripVertical,
        faRightFromBracket,
        faWallet,
        faMoneyBillTrendUp,
        faMoneyBillTransfer,
        faSackDollar,
        faCircleDollarToSlot,
        faChevronDown,
        faCaretDown,
        faTriangleExclamation,
        faEllipsisVertical,
        faSquarePen,
        faPenToSquare,
        faAddressBook,
        faCircle,
        faArrowsRotate,
        faFilter,
        faArrowUpLong,
        faArrowDownLong,
        faEllipsis,
        faGear,
        faCheck,
        faCirclePlus,
        faCopy,
        faMagnifyingGlass,
        faDownload,
        faUpload,
        faFileArrowUp,
        faCircleExclamation,
        faClock,
        faTriangleExclamation,
        faFileCirclePlus,
        faPlusMinus,
        faSquareCaretDown,
        faPaperclip,
        faCircleMinus,
        faArrowRightArrowLeft,
        faArrowRotateLeft,
        faBullhorn,
        faThumbtack,
        faCalendarDays,
        faUpRightAndDownLeftFromCenter,
        faExpand,
        faCompress,
        faListCheck,
        faTableCells,
        faArrowUpFromBracket,
        regularCheck,
        regularCircleXMark
    )
}